"use strict";
(function(){
    var app = angular.module("ctera", [
        /* THIRD PARTY */
        "ngStorage",
        "ui.router",
        "ngAnimate",
        "ngTouch",
        "ngSanitize",
        "mgcrea.ngStrap",
        "gettext",
        /*CONFIG*/
        "ctera.shared.config.ngStorage",
        "ctera.shared.config.saveLostRoute",



        /* CTERA SHARED MODULES */
        "ctera.shared.services.server",
        "ctera.shared.services.queryString",
        "ctera.shared.services.xml",

        /* SERVICES */
        "ctera.endUser.services.login",
        "ctera.endUser.services.password.strength",
        "ctera.shared.services.customization",
        "ctera.shared.services.language",

        /* CONTROLLERS */
        "ctera.endUser.controllers.login",
        "ctera.endUser.controllers.sign.in",
        "ctera.endUser.controllers.sign.up",
        "ctera.endUser.controllers.forgot.password",
        "ctera.endUser.controllers.password.expired",

        /* MODULES */
        "ctera.modules.installAgent",
        "ctera.endUser.filters.url",
        "ctera.modules.eula",
        "ctera.shared.services.http.interceptor",

        /* DIRECTIVES */
        "ctera.endUser.directives.capsLockIndicator"
    ]);

    app.constant("$GLOBAL", {
        "COMMON_PATH": Global.COMMON_PATH,
        "RELATIVE_PATH": Global.COMMON_PATH + "portalbeta/",
        "TEMPLATE_PATH": Global.COMMON_PATH + "portalbeta/templates/",
        "THIRD_PARTY_PATH": Global.COMMON_PATH + "portalbeta/third-party/",
        "MODULES_PATH": Global.COMMON_PATH + "portalbeta/modules/",
        "CLOUD_DRIVE_PATH": "/cloudDrive/",
        "DATE_FORMAT": "MMM D YYYY",
        "TIME_FORMAT": "h:mm a",
        "INPUT_DATE_FORMAT": "M/D/YYYY",
        "INPUT_DATE_FORMAT_STRAP": "M/d/yyyy",
        "SCHEMA_DATE_FORMAT"	  : "yyyy-MM-dd", // this is for ANGULAR parser (for example when using bs-datepicker)
        "SCHEMA_DATE_FORMAT_MOMENT": "YYYY-MM-DD"// this is for MOMENT.js parser
    });


    app.config(function($sessionStorageProvider){
        $sessionStorageProvider.remove("includeDeleted") ;
        $sessionStorageProvider.remove("devicesListOwnedByToggleValue") ;
        $sessionStorageProvider.remove("showTutorial") ;
    });

    app.config(function ($GLOBAL, $stateProvider, $urlRouterProvider, $locationProvider) {
             $locationProvider.hashPrefix('');
            $urlRouterProvider.when("/","");

            // For any unmatched url
            $urlRouterProvider.otherwise("/signin");

            $stateProvider

                .state('signin',{
                    "title": "Sign In",
                    "url": "/signin?infoMsg",
                    "account": "user",
                    "templateUrl": $GLOBAL.TEMPLATE_PATH+"login/signin/login.signin.tpl.html",
                    "controller": "SignInCtrl"
                })

                .state('signup',{
                    "title": "Signup to Ctera",
                    "url": "/signup",
                    "account": "user",
                    "templateUrl": $GLOBAL.TEMPLATE_PATH+"login/signup/login.signup.tpl.html",
                    "controller": "SignUpCtrl"
                })

                .state('forgotPassword',{
                    "title": "Reset password",
                    "url": "/forgot-password",
                    "account": "user",
                    "templateUrl": $GLOBAL.TEMPLATE_PATH+"login/forgotPassword/login.forgot.password.tpl.html",
                    "controller": "forgotPasswordCtrl"
                })

                .state('passwordExpired',{
                    "title": "Password expired",
                    "url": "/password-expired",
                    "account": "user",
                    "templateUrl": $GLOBAL.TEMPLATE_PATH+"login/passwordExpired/login.password.expired.tpl.html",
                    "controller": "passwordExpiredCtrl"
                });

        }
    );


    app.config(function(caHttpInterceptorProvider){
        caHttpInterceptorProvider.setInterceptor();
    });
    app.config(function ($provide) {
            $provide.value('defaultLanguage', 'English');
        }
    );

    app.config(
        function ($dropdownProvider) {
            angular.extend($dropdownProvider.defaults, {
                html: true
            });
        }
    );
    app.run(['$rootScope','$GLOBAL', '$state', '$localStorage', 'gettextCatalog','caCustomization', "caQueryString","caLanguage", function($rootScope, $GLOBAL, $state, $localStorage, gettextCatalog,caCustomization,caQueryString,caLanguage){
        Global.setLangConstants(gettextCatalog,$rootScope);

        var qsParams = caQueryString.parse(location.search);

        if( qsParams.mobileApp=="1" || qsParams.mobileApp=="true")
        {
            $rootScope.mobileApp = true;
            $(document.body).addClass("ca-mobile-app") ;
        }

        var language = $localStorage.Language ;
        if (qsParams.lang) {
            var publicInfo = window.publicInfo  || window.configCustomization;
            if (publicInfo.supportedLanguages &&  _.findIndex(publicInfo.supportedLanguages, function(l) { return l.val.toLowerCase() == qsParams.lang.toLowerCase(); })>-1){
                language = qsParams.lang.toLowerCase();
            }

        }


        caLanguage.setLanguage(language || "en").then(function(){
            $rootScope.appReady = true ;
        });


        gettextCatalog.debug = false;

        //disable right click
        $('body').on("contextmenu", function(e) {
            e.preventDefault();
        });

        $rootScope.GLOBAL = $GLOBAL;
        $rootScope.$state = $state;

        //$rootScope.$on('$stateChangeSuccess', function (event, current, previous) {
        //});
    }]);
})();